import { useEffect } from 'react'
import { navigate } from 'gatsby'

// redirect to acta_futura page
export default function redir() {
  useEffect(() => {
    navigate('/resources/acta_futura/', { replace: true })
  }, [])

  return null
}
